<template lang="pug">
  div
    router-view
    CCard
      CCardBody
        CRow
          CCol(sm=5)
            h4(class="card-title mb-0") Категории

        vuecrud(selectedtable = "categories", :columnsupdated="columnsupdated", :opts="opts", style="margin-top:15px")
          template(v-slot:row="binds")
            tr
              td
                div
                  img(:src="'/img/services/' + binds.row.service + '.ico'")
                  span &nbsp; {{binds.row.name}}
                div {{binds.row.path}}
              td
                div {{format(binds.row._sales)}}
              td
                div {{format(binds.row._oborot)}}
              td
                div {{format(binds.row._sellers)}}
              td
                div {{format(binds.row._brands)}}
              td
                div {{format(binds.row._goods)}}

</template>

<script>

export default {
  name: 'Categories',
  data: function () {
    return {
      show: true,
      isCollapsed: true,

      opts: {
        canAdd: false,
        canEdit: false,
        canDelete: false
      }

    }
  },
  methods: {
    columnsupdated: function (cols) {

      //cols.deleteAll();


      // Изменить колонку ID
      cols.delete('Id');

      cols.set('name', {label: "Название", hasFilter: true, });
      cols.set('_sales', {label: "Продаж", hasFilter: true, });
      cols.set('_sellers', {label: "Продавцов", hasFilter: true, });
      cols.set('_brands', {label: "Брендов", hasFilter: true, });
      cols.set('_oborot', {label: "Выручка", hasFilter: true, });
      cols.set('_goods', {label: "Товаров", hasFilter: true, });


      cols.delete('_avgPrice');
      cols.delete('service');
      cols.delete('sourceID');
      cols.delete('path');
      cols.delete('sharedKey');
      cols.delete('query');
      cols.delete('totalGoods');
      cols.delete('lastParsedTimeMS');
      cols.delete('cache');
      cols.delete('startScrapingTime');
      cols.delete('endScrapingTime');

      cols.delete('_sellersNN');
      cols.delete('_brandsNN');
      cols.delete('_goodsNN');
      cols.delete('_SPS');
      cols.delete('_SPB');
      cols.delete('_SPP');
      cols.delete('_TSTC');
      cols.delete('_TSTS');
      cols.delete('_GI');
      cols.delete('_LI');

    },

    gotoItem(row) {
      this.$router.push({name: 'Category', params: {id: row.sourceID} });
    },


    format(number) {
      return (new Intl.NumberFormat('ru-RU').format(Math.round(number))).toString();
    }

  }
}
</script>

<style scoped>
.value {
  color: blue;
  cursor: pointer;
}
.value:hover {
  text-decoration: underline;
}
</style>
